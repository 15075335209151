import * as Sentry from '@sentry/browser';

export function initSentry() {
  const { sentry } = window.cloud;

  if (sentry && sentry.dsn) {
    Sentry.init({
      dsn: sentry.dsn,
      environment: sentry.environment,
      release: sentry.release,
      tags: sentry.tags,
      user: sentry.user,
      ignoreErrors: ['latLngToLayerPoint'],
      blacklistUrls: [/https?:\/\/maps\.googleapis\.com/],
    });
    Sentry.withScope((scope) => scope.setUser(sentry.user));
  }
}

export function logException(e) {
  Sentry.captureException(e);
}
